<template>
    <v-container>
        <TasksTableCopy />
    </v-container>  
</template>

<script>
import TasksTableCopy from '@/components/templates/TasksTableCopy'
export default {
    components: {
        TasksTableCopy
    }
}
</script>