<template>
	<v-chip 
		:color="color(status)"
		:outlined="status == 'Przyjęte' && (role == 'administrator' || role == 'moderator') || status == 'Do sprawdzenia' && (role == 'administrator' || role == 'moderator') || status == 'Do poprawki' && (role == 'administrator' || role == 'moderator') || role == 'korektor' && status == 'Oczekujące'"

		:text-color="fontColor(status)"
	>
			{{ status }}
	</v-chip>
</template>

<script>
import { chipColors } from '@/mixins/ChipColors';

export default {
	mixins: [chipColors],
	props: {
		status : {
			type: String
		}
	}

}
</script>