var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-2  text-left"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"350px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 mb-4",attrs:{"readonly":"","color":"indigo","dark":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on),[_vm._v(" "+_vm._s(_vm.date)+" ")])]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","type":"month"},on:{"change":function($event){return _vm.dateChange(_vm.date)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',{staticClass:"d-flex flex-row align-baseline"},[_c('v-text-field',{staticClass:"mb-3 mr-5",attrs:{"value":_vm.getTaskSearchCopy,"label":"Filtruj","single-line":"","hide-details":""},on:{"input":_vm.setTaskSearchCopy}}),_c('v-switch',{staticClass:"mr-4",attrs:{"label":"Zakończone"},on:{"change":function($event){return _vm.updateFilterSwitchEnd()}},model:{value:(_vm.getSwitchEnd),callback:function ($$v) {_vm.getSwitchEnd=$$v},expression:"getSwitchEnd"}}),_c('v-switch',{attrs:{"label":"Anulowane"},on:{"change":function($event){return _vm.updateFilterSwitchReject()}},model:{value:(_vm.getSwitchReject),callback:function ($$v) {_vm.getSwitchReject=$$v},expression:"getSwitchReject"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"loading":!_vm.isDownloaded,"search":_vm.getTaskSearchCopy,"headers":_vm.headers,"items":_vm.filteredItems,"item-key":"id","sort-by":['dateCp'],"hide-default-footer":true,"disable-pagination":true,"sort-desc":false},scopedSlots:_vm._u([{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientId ? item.clientId.name : '')+" ")]}},{key:"item.dateCp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateCp ? item.dateCp.split("T")[0] : '')+" "+_vm._s(_vm.correctDate(item.dateCp))+" ")]}},{key:"item.copyRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.copyRate ? item.copyRate.toFixed(2): '')+" ")]}},{key:"item.fullRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullRate ? item.fullRate.toFixed(2) : '')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-btn',{staticClass:"mt-1 mb-1 mr-2 white--text",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.changeRoute(item._id)}}},[_vm._v(" Zobacz ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }